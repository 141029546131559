/**
 * 共通
 */
export const LOADING_START = "LOADING_START";
export const LOADING_STOP = "LOADING_STOP";
/**
 * ログイン
 */
export const ACTION_LOGIN = "ACTION_LOGIN";
export const UPDATE_TEMPORARY_PASSWORD = "UPDATE_TEMPORARY_PASSWORD";
/**
 * お知らせ
 */
export const DIALOG_START = "DIALOG_START";
export const DIALOG_STOP = "DIALOG_STOP";
export const FETCH_NEWS = "FETCH_NEWS";
export const CREATE_NEWS = "CREATE_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const UPDATE_INQUIRY_DETAILS = "UPDATE_INQUIRY_DETAILS";
export const FETCH_PRODUCT_LIST = "FETCH_PRODUCT_LIST"; // 大カテゴリの取得（大カテゴリに紐づく中カテゴリ、商品情報も合わせて取得）
export const SAVE_NEWS_IMG_TO_S3 = "SAVE_NEWS_IMG_TO_S3";
/**
 * 注文
 */
export const GET_ORDER_ALL_PAGINATION = "GET_ORDER_ALL_PAGINATION";
export const GET_ORDER_PDF_URL_S3 = "GET_ORDER_PDF_URL_S3";
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL";
/**
 * 商品
 */
export const FETCH_PRODUCTS = "FETCH_PRODUCTS"; // 商品一覧の取得（商品に紐づく大カテゴリ、中カテゴリ、単位、加工も合わせて取得）
export const FETCH_PRODUCT_BY_PRODUCT_ID = "FETCH_PRODUCT_BY_PRODUCT_ID"; // 商品IDを元に商品を取得
export const FETCH_UNIT = "FETCH_UNIT";
export const FETCH_PROCESS = "FETCH_PROCESS";
export const SAVE_PRODUCT = "SAVE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const SET_PRODUCT_DISABLED_FLG = "SET_PRODUCT_DISABLED_FLG";
export const SAVE_BULK_PRODUCTS_JSON = "SAVE_BULK_PRODUCTS_JSON"; // 一括登録する商品のリストをJSONでS3に保存する
export const SAVE_BULK_PRODUCT = "SAVE_BULK_PRODUCT"; // 商品一括登録
export const SAVE_BULK_PRODUCT_STATUS = "SAVE_BULK_PRODUCT_STATUS";
export const UPDATE_BULK_PRODUCT = "UPDATE_BULK_PRODUCT"; // 商品一括更新
export const DELETE_BULK_PRODUCT = "DELETE_BULK_PRODUCT"; // 商品一括削除
export const UPDATE_BULK_PRODUCT_DISP_NO = "UPDATE_BULK_PRODUCT_DISP_NO"; // 商品の並び順一括更新
/**
 * 得意先
 */
export const FETCH_CUSTOMER_ALL = "FETCH_CUSTOMER_ALL";
export const SAVE_CUSTOMER = "SAVE_CUSTOMER"; // 得意先の登録
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"; // 得意先の更新
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"; // 得意先の削除
export const DISABLE_CUSTOMER = "DISABLE_CUSTOMER"; // 得意先の無効化
export const SAVE_BULK_CUSTOMERS_JSON = "SAVE_BULK_CUSTOMERS_JSON"; // 一括登録する得意先のリストをJSONでS3に保存する
export const SAVE_BULK_CUSTOMER = "SAVE_BULK_CUSTOMER"; // 得意先一括登録
export const SAVE_BULK_CUSTOMER_STATUS = "SAVE_BULK_CUSTOMER_STATUS";
/**
 * 得意先の従業員
 */
export const FETCH_CUSTOMER_STAFF_BY_ID = "FETCH_CUSTOMER_STAFF_BY_ID";
export const SAVE_CUSTOMER_STAFF = "SAVE_CUSTOMER_STAFF"; // 得意先の登録
export const DELETE_CUSTOMER_STAFF = "DELETE_CUSTOMER_STAFF"; // 得意先の削除
export const UPDATE_CUSTOMER_STAFF = "UPDATE_CUSTOMER_STAFF"; // 得意先の従業員の更新
export const ENABLE_CUSTOMER_STAFF = "ENABLE_CUSTOMER_STAFF"; // 得意先の従業員の有効化
export const DISABLE_CUSTOMER_STAFF = "DISABLE_CUSTOMER_STAFF"; // 得意先の従業員の無効化
export const CANCEL_ASSOCIATE_CUSTOMER_STAFF =
  "CANCEL_ASSOCIATE_CUSTOMER_STAFF"; // 得意先の従業員のLINEID紐づけ解除

/**
 * 担当者
 */
export const FETCH_EMPLOYEES = "FETCH_EMPLOYEES"; // 担当者のリスト取得
export const SAVE_EMPLOYEE = "SAVE_EMPLOYEE"; // 担当者の登録
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE"; // 担当者の更新
export const ENABLE_EMPLOYEE = "ENABLE_EMPLOYEE"; // 担当者の有効化
export const DISABLE_EMPLOYEE = "DISABLE_EMPLOYEE"; // 担当者の無効化
export const CANCEL_ASSOCIATE_EMPLOYEE = "CANCEL_ASSOCIATE_EMPLOYEE"; // 担当者のLINE紐づけ解除
export const RESET_STAFF_PASS = "RESET_STAFF_PASS"; // 担当者パスリセット
export const CREATE_STAFF_QR_PDF = "CREATE_STAFF_QR_PDF"; // 担当者パスQR付きPDF
export const CREATE_STAFF_PASS_PDF = "CREATE_STAFF_PASS_PDF"; // 担当者パス文字列PDF

/**
 * 加工
 */
export const FETCH_PROCESSES = "FETCH_PROCESSES"; // 加工種別のリスト取得
export const SAVE_PROCESSES = "SAVE_PROCESSES"; // 加工種別の新規作成
export const UPDATE_PROCESSES = "UPDATE_PROCESSES"; // 加工種別の更新
/**
 * カテゴリ
 */
export const FETCH_LARGE_MIDDLE_CATEGORYS = "FETCH_LARGE_MIDDLE_CATEGORYS"; // すべての大カテゴリーと大カテゴリに紐づく中カテゴリー
export const SAVE_CATEGORY = "SAVE_CATEGORY"; // カテゴリの登録
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"; // カテゴリの更新

/**
 * 設定
 */
export const GET_HOLIDAY_JSON = "GET_HOLIDAY_JSON"; // 非営業日のJSONを取得
export const SAVE_JSON = "SAVE_JSON"; // 非営業日のJSONをS3に保存
