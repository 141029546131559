import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
import axios from "axios";
import {
  /**
   * 共通
   */
  LOADING_START,
  LOADING_STOP,
  /**
   * ログイン
   */
  ACTION_LOGIN,
  UPDATE_TEMPORARY_PASSWORD,
  /**
   * お知らせ
   */
  DIALOG_STOP,
  DIALOG_START,
  FETCH_NEWS,
  CREATE_NEWS,
  DELETE_NEWS,
  UPDATE_NEWS,
  UPDATE_INQUIRY_DETAILS,
  FETCH_PRODUCT_LIST,
  SAVE_NEWS_IMG_TO_S3,
  /**
   * 注文
   */
  GET_ORDER_ALL_PAGINATION,
  GET_ORDER_PDF_URL_S3,
  GET_ORDER_DETAIL,
  /**
   * 商品
   */
  FETCH_PRODUCTS,
  FETCH_PRODUCT_BY_PRODUCT_ID,
  FETCH_UNIT,
  FETCH_PROCESS,
  SAVE_PRODUCT,
  UPDATE_PRODUCT,
  SAVE_BULK_PRODUCTS_JSON,
  SAVE_BULK_PRODUCT,
  SAVE_BULK_PRODUCT_STATUS,
  UPDATE_BULK_PRODUCT,
  DELETE_BULK_PRODUCT,
  SET_PRODUCT_DISABLED_FLG,
  UPDATE_BULK_PRODUCT_DISP_NO,
  /**
   * 得意先
   */
  FETCH_CUSTOMER_ALL,
  SAVE_CUSTOMER,
  UPDATE_CUSTOMER,
  DISABLE_CUSTOMER,
  SAVE_BULK_CUSTOMERS_JSON,
  SAVE_BULK_CUSTOMER,
  SAVE_BULK_CUSTOMER_STATUS,
  /**
   * 担当者
   */
  FETCH_EMPLOYEES,
  SAVE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  ENABLE_EMPLOYEE,
  DISABLE_EMPLOYEE,
  CANCEL_ASSOCIATE_EMPLOYEE,
  /**
   * 得意先の従業員
   */
  FETCH_CUSTOMER_STAFF_BY_ID,
  SAVE_CUSTOMER_STAFF,
  UPDATE_CUSTOMER_STAFF,
  DELETE_CUSTOMER_STAFF,
  ENABLE_CUSTOMER_STAFF,
  DISABLE_CUSTOMER_STAFF,
  CANCEL_ASSOCIATE_CUSTOMER_STAFF,
  RESET_STAFF_PASS,
  CREATE_STAFF_PASS_PDF,
  CREATE_STAFF_QR_PDF,
  /**
   * 加工
   */
  FETCH_PROCESSES,
  SAVE_PROCESSES,
  UPDATE_PROCESSES,
  /**
   * カテゴリ
   */
  FETCH_LARGE_MIDDLE_CATEGORYS,
  SAVE_CATEGORY,
  UPDATE_CATEGORY,
  /**
   * 設定
   */
  GET_HOLIDAY_JSON,
  SAVE_JSON,
} from "./action-types";

import {
  SAVE_LINE_ID,
  CHANGE_LOADING_STATUS,
  CHANGE_IS_FOREGROUND,
  CHANGE_ORDER_STATUS,
  SET_ORDER_LIST,
  UPDATE_ORDER_LIST,
  SET_CURRENT_ORDER,
  UPDATE_CURRENT_ORDER,
  SET_NEW_ORDER,
  UPDATE_NEW_ORDER,
  SET_STATUS_ID_CHAR,
  SET_NEW_ORDER_NUMBER,
  CHANGE_DIALOG_STATUS,
  SET_CURRENT_STATUS,
  SET_NEWS_LIST,
  SET_CURRENT_NEWS,
  SET_IS_NEW_NEWS,
  SET_SEND_NEW_NEWS,
  SET_INQUIRY_DETAILS,
  UPDATE_PRODUCT_LIST,
  SET_NEWS_BLOB,
  SET_NEWS_BLOB_KEY,
  /**
   * ログイン
   */
  CHANGE_LOGIN_STATUS,
  SET_IS_LOGIN_SUCCESS,
  SET_LOGIN_USER,
  /**
   * 注文
   */
  ADD_ORDER_LIST,
  SET_NOW_RUNNING_GET_ORDER_ALL_PAGINATION,
  SET_ORDER_PDF_URL,
  SET_ORDER_DETAIL,
  /**
   * 商品
   */
  SET_PRODUCTS,
  SET_UNIT_LIST,
  SET_PROCESS_LIST,
  SET_IS_NEW_PRODUCT,
  SET_NEW_PRODUCT,
  SET_CURRENT_PRODUCT,
  SET_UPDATE_PRODUCT,
  SET_BULK_PRODUCT,
  SET_DUPLICATE_PRODUCTS,
  SET_BULK_UPDATE_PRODUCTS,
  SET_BULK_PRODUCTS_EXEC_TASK_NAME,
  SET_BULK_PRODUCTS_SAVE_STATUS,
  SET_IS_PRODUCT_ID_DUPLICATE,
  SET_UPDATE_PRODUCTS_BY_PRODUCT_ID,
  SET_ADD_PRODUCT_PRODUCTS,
  SET_UPDATE_SORT_PRODUCTS,
  /**
   * 得意先
   */
  SET_CUSTOMER_ALL,
  SET_CURRENT_CUSTOMER,
  SET_IS_NEW_CUSTOMER,
  SET_NEW_CUSTOMER,
  SET_UPDATE_CUSTOMER,
  SET_BULK_CUSTOMER,
  SET_BULK_CUSTOMERS_EXEC_TASK_NAME,
  SET_BULK_CUSTOMERS_SAVE_STATUS,
  /**
   * 得意先の従業員
   */
  SET_CUSTOMER_ALL_STAFF,
  SET_CURRENT_CUSTOMER_STAFF,
  SET_IS_NEW_CUSTOMER_STAFF,
  SET_SELECTED_CUSTOMER_STAFF,
  SET_QR_STAFF_PDF,
  SET_PASS_STAFF_PDF,
  // SET_NEW_CUSTOMER_STAFF,
  // SET_UPDATE_CUSTOMER_STAFF,
  /**
   * 担当者
   */
  SET_EMPLOYEES,
  SET_CURRENT_EMPLOYEE,
  SET_IS_NEW_EMPLOYEE,
  SET_NEW_EMPLOYEE,
  SET_UPDATE_EMPLOYEE,
  SET_SELECTED_EMPLOYEES,
  SET_NEW_STAFF_PDF,
  /**
   * 加工
   */
  SET_PROCESSES,
  SET_EDIT_PROCESSES,
  SET_IS_NEW_PROCESS,
  /**
   * カテゴリ
   */
  SET_LARGE_MIDDLE_CATEGORY_LIST,
  SET_CURRENT_CATEGORY,
  SET_IS_NEW_CATEGORY,
  SET_NEW_CATEGORY,
  SET_UPDATE_CATEGORY,
  SET_IS_UPLOADING,
  /**
   * 設定
   */
  SET_HOLIDAY_JSON,
  /**
   * 各画面のページ番号
   */
  SET_CUSTOMER_LIST_AREA_CURRENT_PAGE,
  SET_PRODUCT_LIST_AREA_CURRENT_PAGE,
} from "./mutation-types";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lineId: "",
    isUploading: false,
    isLoading: false,
    isForeground: false,
    isDialog: false,
    currentStatusStore: null,
    newsList: null,
    currentNews: null,
    isNewNews: false,
    newNews: {},
    inquiryDetailsForSave: null,
    productAll: null,
    newsBlob: null,
    key: null,
    /**
     * ログイン
     */
    isLogin: false,
    isLoginSuccess: false,
    loginUser: {},
    /**
     * 注文
     */
    orderStatus: "",
    orderListInfo: [],
    nowRunningIdGetOrderAllPagination: "",
    currentOrder: null,
    newOrderInfo: [],
    orderStatusIdChar: [],
    newOrderNumber: 0,
    orderPdfUrl: null,
    orderDetail: [],
    /**
     * 商品
     */
    products: [],
    currentProduct: {},
    unitList: [],
    processList: [],
    isNewProduct: false,
    newProduct: {},
    updateProduct: {},
    bulkProducts: [],
    bulkProductsExecTaskName: null,
    bulkProductsSaveStatus: false,
    isProductIdDuplicate: false,
    duplicateProducts: [],
    bulkUpdateProducts: [],
    /**
     * 得意先
     */
    customerAll: null,
    currentCustomer: {},
    isNewCustomer: false,
    newCustomer: {},
    updateCustomer: {},
    bulkCustomers: [],
    bulkCustomersExecTaskName: null,
    bulkCustomersSaveStatus: false,
    /**
     * 得意先の従業員
     */
    customerStaffAll: null,
    currentCustomerStaff: {},
    isNewCustomerStaff: false,
    selectedCustomerStaff: [],
    qrStaffPdf: null,
    passStaffPdf: null,
    newStaffPdf: null,
    /**
     * 配達担当者
     */
    employees: [],
    currentEmployee: {},
    isNewEmployee: false,
    newEmployee: {},
    updateEmployee: {},
    selectedEmployees: [],
    /**
     * 加工
     */
    processes: [],
    process: {
      process_name: "",
      sort_no: "",
    },
    isNewProcess: false,
    /**
     * カテゴリ
     */
    largeMiddleCategoryList: [],
    currentCategory: {},
    isNewCategory: false,
    newCategory: {},
    updateCategory: {},
    /**
     * 設定
     */
    holidayJson: {},
    /**
     * 各画面のページ番号
     */
    customerListAreaCurrentPage: 1,
    productListAreaCurrentPage: 1,
  },
  getters: {},
  mutations: {
    [SET_NEW_STAFF_PDF](state, newStaffPdf) {
      state.newStaffPdf = newStaffPdf;
    },
    [SET_PASS_STAFF_PDF](state, passStaffPdf) {
      state.passStaffPdf = passStaffPdf;
    },
    [SET_QR_STAFF_PDF](state, qrStaffPdf) {
      state.qrStaffPdf = qrStaffPdf;
    },
    [SAVE_LINE_ID](state, lineId) {
      state.lineId = lineId;
    },
    [SET_NEWS_BLOB_KEY](state, key) {
      state.key = key;
    },
    [SET_NEWS_BLOB](state, newsBlob) {
      state.newsBlob = newsBlob;
    },
    [SET_SEND_NEW_NEWS](state, newNews) {
      state.newNews = newNews;
    },
    [SET_IS_NEW_NEWS](state, isNewNews) {
      state.isNewNews = isNewNews;
    },
    [SET_NEWS_LIST](state, newsList) {
      state.newsList = newsList;
    },
    [SET_CURRENT_NEWS](state, currentNews) {
      state.currentNews = currentNews;
    },
    [SET_CURRENT_STATUS](state, currentStatusStore) {
      state.currentStatusStore = currentStatusStore;
    },
    [CHANGE_DIALOG_STATUS](state, isDialog) {
      state.isDialog = isDialog;
    },
    [CHANGE_LOADING_STATUS](state, isLoading) {
      state.isLoading = isLoading;
    },
    [CHANGE_IS_FOREGROUND](state, isForeground) {
      state.isForeground = isForeground;
    },
    [SET_STATUS_ID_CHAR](state, orderStatusIdChar) {
      state.orderStatusIdChar = orderStatusIdChar;
    },
    [SET_NEW_ORDER_NUMBER](state, newOrderNumber) {
      state.newOrderNumber = newOrderNumber;
    },
    [CHANGE_ORDER_STATUS](state, status) {
      state.orderStatus = status;
    },
    [SET_ORDER_LIST](state, list) {
      state.orderListInfo = list;
    },
    [ADD_ORDER_LIST](state, list) {
      state.orderListInfo = state.orderListInfo.concat(list);
    },
    [SET_NOW_RUNNING_GET_ORDER_ALL_PAGINATION](state, nowRunningIdGetOrderAllPagination) {
      state.nowRunningIdGetOrderAllPagination = nowRunningIdGetOrderAllPagination;
    },
    // 未使用
    [UPDATE_ORDER_LIST](state, list) {
      state.orderListInfo = list;
    },
    [SET_CURRENT_ORDER](state, singleOrder) {
      state.currentOrder = singleOrder;
    },
    // 未使用
    [UPDATE_CURRENT_ORDER](state, singleOrder) {
      state.currentOrder = singleOrder;
    },
    [SET_NEW_ORDER](state, newOrderInfo) {
      state.newOrderInfo = newOrderInfo;
    },
    [UPDATE_NEW_ORDER](state, newOrderInfo) {
      state.newOrderInfo = newOrderInfo;
    },
    [SET_INQUIRY_DETAILS](state, inquiryDetailsForSave) {
      state.inquiryDetailsForSave = inquiryDetailsForSave;
    },
    /**
     * ログイン
     */
    [CHANGE_LOGIN_STATUS](state, loginStatus) {
      state.isLogin = loginStatus;
    },
    [SET_IS_LOGIN_SUCCESS](state, isLoginSuccess) {
      state.isLoginSuccess = isLoginSuccess;
    },
    [SET_LOGIN_USER](state, loginUser) {
      state.loginUser = loginUser;
    },
    /**
     * 注文
     */
    [SET_ORDER_PDF_URL](state, orderPdfUrl) {
      state.orderPdfUrl = orderPdfUrl;
    },
    [SET_ORDER_DETAIL](state, orderDetail) {
      state.orderDetail = orderDetail;
    },
    /**
     * 商品
     */
    [UPDATE_PRODUCT_LIST](state, productAll) {
      state.productAll = productAll;
    },
    [SET_PRODUCTS](state, list) {
      // 表示順の昇順に並び替え
      list.sort(function(a, b) {
        const aNum = parseInt(a.m_product_disp_no);
        const bNum = parseInt(b.m_product_disp_no);
        return aNum - bNum;
      });
      state.products = list;
    },
    [SET_CURRENT_PRODUCT](state, currentProduct) {
      state.currentProduct = currentProduct;
    },
    [SET_UNIT_LIST](state, list) {
      state.unitList = list;
    },
    [SET_PROCESS_LIST](state, list) {
      state.processList = list;
    },
    [SET_IS_NEW_PRODUCT](state, isNewProduct) {
      state.isNewProduct = isNewProduct;
    },
    [SET_NEW_PRODUCT](state, newProduct) {
      state.newProduct = newProduct;
    },
    [SET_UPDATE_PRODUCT](state, updateProduct) {
      state.updateProduct = updateProduct;
    },
    [SET_BULK_PRODUCT](state, bulkProducts) {
      state.bulkProducts = bulkProducts;
    },
    [SET_DUPLICATE_PRODUCTS](state, duplicateProducts) {
      state.duplicateProducts = duplicateProducts;
    },
    [SET_BULK_UPDATE_PRODUCTS](state, bulkUpdateProducts) {
      state.bulkUpdateProducts = bulkUpdateProducts;
    },
    [SET_BULK_PRODUCTS_EXEC_TASK_NAME](state, bulkProductsExecTaskName) {
      state.bulkProductsExecTaskName = bulkProductsExecTaskName;
    },
    [SET_BULK_PRODUCTS_SAVE_STATUS](state, bulkProductsSaveStatus) {
      state.bulkProductsSaveStatus = bulkProductsSaveStatus;
    },
    [SET_IS_PRODUCT_ID_DUPLICATE](state, isProductIdDuplicate) {
      state.isProductIdDuplicate = isProductIdDuplicate;
    },
    [SET_ADD_PRODUCT_PRODUCTS](state, product) {
      state.products.push(product)
    },
    [SET_UPDATE_SORT_PRODUCTS](state, sortedProducts) {
      sortedProducts.forEach(sortedProduct => {
        const targetIndex = state.products.findIndex(product => {
          return product.m_product_product_id === sortedProduct.m_product_product_id
        });

        if (targetIndex != -1) {
          state.products[targetIndex] = sortedProduct;
        }
      })
      // 表示順の昇順に並び替え
      state.products.sort(function(a, b) {
        const aNum = parseInt(a.m_product_disp_no);
        const bNum = parseInt(b.m_product_disp_no);
        return aNum - bNum;
      });
    },
    [SET_UPDATE_PRODUCTS_BY_PRODUCT_ID](state, product) {
      const index = state.products.findIndex(item => item.m_product_product_id == product.m_product_product_id);
      state.products[index] = product;
    },
    /**
     * 得意先
     */
    [SET_CUSTOMER_ALL](state, customerAll) {
      state.customerAll = customerAll;
    },
    [SET_CURRENT_CUSTOMER](state, currentCustomer) {
      state.currentCustomer = currentCustomer;
    },
    [SET_IS_NEW_CUSTOMER](state, isNewCustomer) {
      state.isNewCustomer = isNewCustomer;
    },
    [SET_NEW_CUSTOMER](state, newCustomer) {
      state.newCustomer = newCustomer;
    },
    [SET_UPDATE_CUSTOMER](state, updateCustomer) {
      state.updateCustomer = updateCustomer;
    },
    [SET_BULK_CUSTOMER](state, bulkCustomers) {
      state.bulkCustomers = bulkCustomers;
    },
    [SET_BULK_CUSTOMERS_EXEC_TASK_NAME](state, bulkCustomersExecTaskName) {
      state.bulkCustomersExecTaskName = bulkCustomersExecTaskName;
    },
    [SET_BULK_CUSTOMERS_SAVE_STATUS](state, bulkCustomersSaveStatus) {
      state.bulkCustomersSaveStatus = bulkCustomersSaveStatus;
    },
    /**
     * 得意先の従業員
     */
    [SET_CUSTOMER_ALL_STAFF](state, customerStaffAll) {
      state.customerStaffAll = customerStaffAll;
    },
    [SET_CURRENT_CUSTOMER_STAFF](state, currentCustomerStaff) {
      state.currentCustomerStaff = currentCustomerStaff;
    },
    [SET_IS_NEW_CUSTOMER_STAFF](state, isNewCustomerStaff) {
      state.isNewCustomerStaff = isNewCustomerStaff;
    },
    [SET_SELECTED_CUSTOMER_STAFF](state, selectedCustomerStaff) {
      state.selectedCustomerStaff = selectedCustomerStaff;
    },

    /**
     * 担当者
     */
    [SET_EMPLOYEES](state, employees) {
      state.employees = employees;
    },
    [SET_CURRENT_EMPLOYEE](state, currentEmployee) {
      state.currentEmployee = currentEmployee;
    },
    [SET_IS_NEW_EMPLOYEE](state, isNewEmployee) {
      state.isNewEmployee = isNewEmployee;
    },
    [SET_NEW_EMPLOYEE](state, newEmployee) {
      state.newEmployee = newEmployee;
    },
    [SET_UPDATE_EMPLOYEE](state, updateEmployee) {
      state.updateEmployee = updateEmployee;
    },
    [SET_SELECTED_EMPLOYEES](state, selectedEmployees) {
      state.selectedEmployees = selectedEmployees;
    },
    /**
     * 加工種別
     */
    [SET_PROCESSES](state, processes) {
      state.processes = processes;
    },
    [SET_EDIT_PROCESSES](state, process) {
      state.process = process;
    },
    [SET_IS_NEW_PROCESS](state, isNewProcess) {
      state.isNewProcess = isNewProcess;
    },
    [SET_IS_UPLOADING](state, isUploading) {
      state.isUploading = isUploading;
    },
    /**
     * カテゴリー
     */
    [SET_LARGE_MIDDLE_CATEGORY_LIST](state, list) {
      let newList = list.map((item) => {
        let object = {
          large_category_id: item.large_category_id,
          category_name: item.category_name,
          middleCategorys: item.middleCategorys,
          sort_no: item.sort_no,
        };
        return object;
      });
      state.largeMiddleCategoryList = newList;
    },
    [SET_CURRENT_CATEGORY](state, currentCategory) {
      state.currentCategory = currentCategory;
    },
    [SET_IS_NEW_CATEGORY](state, isNewCategory) {
      state.isNewCategory = isNewCategory;
    },
    [SET_NEW_CATEGORY](state, newCategory) {
      state.newCategory = newCategory;
    },
    [SET_UPDATE_CATEGORY](state, updateCategory) {
      state.updateCategory = updateCategory;
    },
    [SET_HOLIDAY_JSON](state, holidayJson) {
      state.holidayJson = holidayJson;
    },
    [SET_CUSTOMER_LIST_AREA_CURRENT_PAGE](state, customerListAreaCurrentPage) {
      state.customerListAreaCurrentPage = customerListAreaCurrentPage;
    },
    [SET_PRODUCT_LIST_AREA_CURRENT_PAGE](state, productListAreaCurrentPage) {
      state.productListAreaCurrentPage = productListAreaCurrentPage;
    },
  },
  actions: {
    // TODO ここに来る前にバリデーション実装必須
    [SAVE_NEWS_IMG_TO_S3]: async ({ commit, state, dispatch }) => {
      if (state.key != null && state.key != "" && state.key != undefined) {
        console.log(commit);
        console.log("state", state);
        const apiUrl = process.env.VUE_APP_API_DOMAIN + "s3/url";
        const body = { key: state.key, methodType: "put" };

        let presinedUrl = null;
        // presinedUrl取得処理
        await axios
          .post(apiUrl, body)
          .then((response) => {
            console.log("SAVE_NEWS_IMG_TO_S3", response);
            presinedUrl = response.data;
          })
          .catch((err) => {
            console.error(err);
          });

        if (presinedUrl == null) {
          return;
        }

        // s3に保存処理
        await fetch(presinedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": state.newsBlob.type,
          },
          body: state.newsBlob,
        })
          .then((response) => {
            const pattern = /https:\/\/[^?]+/;
            const s3Url = response.url.match(pattern)[0];
            const addedS3Url = { ...state.newNews };
            addedS3Url.news.url = s3Url;
            console.log("addedS3Url", addedS3Url);
            commit(SET_SEND_NEW_NEWS, addedS3Url);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const deletedS3Url = { ...state.newNews };
        if ("news" in deletedS3Url && "url" in deletedS3Url.news) {
          deletedS3Url.news.url = "";
        }
        console.log("deletedS3Url", deletedS3Url);
        commit(SET_SEND_NEW_NEWS, deletedS3Url);
      }
      // 新規作成or更新
      if (state.isNewNews) {
        await dispatch(`CREATE_NEWS`, { commit, state });
      } else {
        await dispatch(`UPDATE_NEWS`, { commit, state });
      }
    },

    [FETCH_PRODUCT_LIST]: async ({ commit }) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "category/get-large-middle-categorys";
      await axios
        .get(apiUrl, {
          params: {},
        })
        .then((response) => {
          if (response && response.data && response.data.result) {
            commit(UPDATE_PRODUCT_LIST, response.data.result);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [LOADING_START]({ commit }, isForeground = false) {
      console.log("LOADING_START")
      console.log("isForeground", isForeground)
      commit(CHANGE_IS_FOREGROUND, isForeground);
      commit(CHANGE_LOADING_STATUS, true);
    },
    [LOADING_STOP]({ commit }) {
      commit(CHANGE_LOADING_STATUS, false);
    },
    [DIALOG_START]({ commit }) {
      commit(CHANGE_DIALOG_STATUS, true);
    },
    [DIALOG_STOP]({ commit }) {
      commit(CHANGE_DIALOG_STATUS, false);
    },
    [FETCH_NEWS]: async ({ commit }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "news/get-news-all";
      await axios
        .get(apiUrl, {
          params: {},
        })
        .then((response) => {
          console.log("FETCH_NEWS", response);
          if (response && response.data && response.data.result) {
            let news = response.data.result;
            news.sort(function (a, b) {
              return moment(b.updated_at, "YYYY/MM/DD hh:mm:ss").diff(
                moment(a.updated_at, "YYYY/MM/DD hh:mm:ss")
              );
            });
            commit(SET_NEWS_LIST, response.data.result);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [CREATE_NEWS]: async ({ commit, state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "news/save-news";
      const body = state.newNews;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("CREATE_NEWS", response);
          commit(SET_IS_UPLOADING, true);
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    [DELETE_NEWS]: async ({ commit, state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "news/delete-news";
      const body = { news_id: state.currentNews.news_id };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("CREATE_NEWS", response);
          commit(SET_IS_UPLOADING, true);
          commit(SET_CURRENT_NEWS, null);
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    [UPDATE_NEWS]: async ({ commit, state, dispatch }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "news/update-news";
      const body = state.newNews;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("UPDATE_NEWS", response);
          commit(SET_IS_UPLOADING, true);
          // commit(SET_CURRENT_NEWS, response.data.result);
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
      await dispatch(`FETCH_NEWS`, { commit, state });
    },
    [UPDATE_INQUIRY_DETAILS]: async ({ commit, state }) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "order/update-inquiry-details";
      const body = state.inquiryDetailsForSave;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          commit(SET_IS_UPLOADING, true);
          console.log("UPDATE_INQUIRY_DETAILS", response);
          console.log("UPDATE_INQUIRY_DETAILS", commit);
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    /**
     * ログイン
     */
    [ACTION_LOGIN]: async ({ commit }, payload) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "auth/employee/login";
      const body = payload;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("ACTION_LOGIN", response);
          if (response && response.data && response.data.result) {
            commit(SET_IS_LOGIN_SUCCESS, response.data.result.is_login);
            commit(SET_LOGIN_USER, response.data.result.employee);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [UPDATE_TEMPORARY_PASSWORD]: async ({ commit }, payload) => {
      console.log(commit);
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN +
        "auth/employee/temporary-password-update";
      const body = payload;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("UPDATE_TEMPORARY_PASSWORD", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 注文
     */
    [GET_ORDER_ALL_PAGINATION]: async ({ state, commit, dispatch }) => {
      /* 実行中のメソッドが自分自身か判定するために実行IDを管理 */
      const runningId = Math.random().toString(36).substring(2, 11);
      commit(SET_NOW_RUNNING_GET_ORDER_ALL_PAGINATION, runningId);

      /* １回目の取得(ローディングあり) */
      dispatch(LOADING_START);
      await axios
        .get(process.env.VUE_APP_API_DOMAIN + "order/get-all-orders/pagination", {
          params: {
            page: 1
          }
        })
        .then((response) => {
          console.log("GET_ORDER_ALL_PAGINATION", response);

          /* GET_ORDER_ALL_PAGINATIONが新しく実行中なら、処理終了 */
          const hadStartNewProcess = (runningId !== state.nowRunningIdGetOrderAllPagination);
          if (hadStartNewProcess) return;

          /* 取得した結果が正しくない場合、ループ終了 */
          if (!response || !response.data || !response.data.result) return;

          /* 取得した結果が0件の場合、ループ終了 */
          const hasNext = (response.data.result.length === 0);
          if (hasNext) return;

          /* 取得した結果を格納 */
          commit(SET_ORDER_LIST, response.data.result);
          commit(SET_NEW_ORDER_NUMBER, state.orderListInfo.length);
      })
        .catch((err) => {
          console.error(err);
        });
      dispatch(LOADING_STOP);

      /*  */

      /* 2回目以降の取得 */
      let page = 2;
      let needEndProcess = false;
      while (!needEndProcess) {
        /* 取得処理 */
        await axios
          .get(process.env.VUE_APP_API_DOMAIN + "order/get-all-orders/pagination", {
            params: {
              page: page
            }
          })
          .then((response) => {
            console.log("GET_ORDER_ALL_PAGINATION", response);

            /* GET_ORDER_ALL_PAGINATIONが新しく実行中なら、ループ終了 */
            const hadStartNewProcess = (runningId !== state.nowRunningIdGetOrderAllPagination);
            if (hadStartNewProcess) needEndProcess = true;

            /* 取得した結果が正しくない場合、ループ終了 */
            if (!response || !response.data || !response.data.result) needEndProcess = true;

            /* 取得した結果が0件の場合、ループ終了 */
            const hasNext = (response.data.result.length === 0);
            if (hasNext) needEndProcess = true;

            /* 取得した結果を格納 */
            commit(ADD_ORDER_LIST, response.data.result);
            commit(SET_NEW_ORDER_NUMBER, state.orderListInfo.length);
          })
          .catch((err) => {
            console.error(err);
          });
        /* インクリメント */
        page++;
      }
    },
    [GET_ORDER_PDF_URL_S3]: async ({ commit }, payload) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "order/order-pdf-get";
      const body = {
        orderId: payload,
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("GET_ORDER_PDF_URL_S3", response);
          commit(SET_ORDER_PDF_URL, response.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [GET_ORDER_DETAIL]: async ({ commit }, payload) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "order/get-order-detail";
      
      await axios
        .get(apiUrl, {
          params: {
            orderId: payload,
          },
        })
        .then((response) => {
          console.log("FETCH_PRODUCT_BY_PRODUCT_ID", response);
          if (response && response.data && response.data.result) {
            commit(SET_ORDER_DETAIL, response.data.result);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 商品
     */
    [FETCH_PRODUCTS]: async ({ commit }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "product/get-products";
      await axios
        .get(apiUrl, {
          params: {},
        })
        .then((response) => {
          console.log("FETCH_PRODUCTS", response);
          if (response && response.data && response.data.result) {
            commit(SET_PRODUCTS, response.data.result);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [FETCH_UNIT]: async ({ commit }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "unit/get-units";
      await axios
        .get(apiUrl, {
          params: {},
        })
        .then((response) => {
          console.log("FETCH_UNIT", response);
          if (response && response.data && response.data.result) {
            commit(SET_UNIT_LIST, response.data.result);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [FETCH_PROCESS]: async ({ commit }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "process/get-process";
      await axios
        .get(apiUrl, {
          params: {},
        })
        .then((response) => {
          console.log("FETCH_PROCESS", response);
          if (response && response.data && response.data.result) {
            commit(SET_PROCESS_LIST, response.data.result);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_PRODUCT]: async ({ commit, state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "product/save-product";
      const body = state.newProduct;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SAVE_PRODUCT", response);
          if (response.data.statusCode === 200) {
            console.log(200);
            const product = response.data.item;
            commit(SET_ADD_PRODUCT_PRODUCTS, product);
            commit(SET_IS_UPLOADING, true);
          } else if (response.data.statusCode === 409) {
            console.log(409);
            commit(SET_IS_UPLOADING, false);
            commit(SET_IS_PRODUCT_ID_DUPLICATE, true);
          }
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    [FETCH_PRODUCT_BY_PRODUCT_ID]: async ({ commit }, productId) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "product/get-product";
      await axios
        .get(apiUrl, {
          params: {
            productId: productId,
          },
        })
        .then((response) => {
          console.log("FETCH_PRODUCT_BY_PRODUCT_ID", response);
          if (response && response.data && response.data.result) {
            commit(SET_CURRENT_PRODUCT, response.data.result);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [UPDATE_PRODUCT]: async ({ commit, state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "product/update-product";
      const body = state.updateProduct;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("UPDATE_PRODUCT", response);
          const product = response.data.item;
          commit(SET_UPDATE_PRODUCTS_BY_PRODUCT_ID, product);
          commit(SET_IS_UPLOADING, true);
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    [SAVE_BULK_PRODUCTS_JSON]: async ({ state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "products-json/save";
      const body = state.bulkProducts;

      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SAVE_BULK_PRODUCTS_JSON", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_BULK_PRODUCT]: async ({ commit }, payload) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "execution";
      await axios
        .post(apiUrl, payload)
        .then((response) => {
          console.log("SAVE_BULK_PRODUCT", response);
          if (response && response.data) {
            commit(
              SET_BULK_PRODUCTS_EXEC_TASK_NAME,
              response.data.executionArn
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [UPDATE_BULK_PRODUCT]: async ({ commit }, payload) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "execution";
      await axios
        .post(apiUrl, payload)
        .then((response) => {
          console.log("UPDATE_BULK_PRODUCT", response);
          if (response && response.data) {
            commit(
              SET_BULK_PRODUCTS_EXEC_TASK_NAME,
              response.data.executionArn
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [DELETE_BULK_PRODUCT]: async ({ commit }, payload) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "execution";
      await axios
        .post(apiUrl, payload)
        .then((response) => {
          console.log("DELETE_BULK_PRODUCT", response);
          if (response && response.data) {
            commit(
              SET_BULK_PRODUCTS_EXEC_TASK_NAME,
              response.data.executionArn
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_BULK_PRODUCT_STATUS]: async ({ commit, state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "execution/status";
      const body = {
        executionArn: state.bulkProductsExecTaskName,
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SAVE_BULK_PRODUCT_STATUS", response);
          if (response) {
            if ("output" in response.data) {
              commit(SET_IS_UPLOADING, true);
              commit(SET_BULK_PRODUCTS_SAVE_STATUS, true);
            } else if (response.data.status == "FAILED") {
              // エラーになった場合
              commit(SET_IS_UPLOADING, true);
              commit(SET_BULK_PRODUCTS_SAVE_STATUS, true);
            } else {
              commit(SET_IS_UPLOADING, true);
              commit(SET_BULK_PRODUCTS_SAVE_STATUS, false);
            }
          }
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    [SET_PRODUCT_DISABLED_FLG]: async ({ state }, payload) => {
      console.log("SET_PRODUCT_DISABLED_FLG", state);

      payload.product.m_product_disabled_flg = payload.product.m_product_disabled_flg == 0 ? 1 : 0;

      const apiUrl = process.env.VUE_APP_API_DOMAIN + "product/set-product-disabled-flg";
      const body = { product: payload.product };

      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SET_PRODUCT_DISABLED_FLG", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [UPDATE_BULK_PRODUCT_DISP_NO]: async ({ commit }, payload) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "product/sort";
      const body = {
        products: payload
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("UPDATE_BULK_PRODUCT_DISP_NO", response);
          if (response.data.items.length != 0) {
            commit("SET_UPDATE_SORT_PRODUCTS", response.data.items);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 得意先
     */
    [FETCH_CUSTOMER_ALL]: async ({ commit }, payload) => {
      console.log("payload", payload);
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "customer/get-customer-all";
      await axios
        .get(apiUrl, {
          params: {
            include_disabled: payload.include_disabled
          },
        })
        .then((response) => {
          console.log("FETCH_CUSTOMER_ALL", response);
          const data = response.data.result;
          commit(SET_CUSTOMER_ALL, data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_CUSTOMER]: async ({ commit, state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "customer/save-customer";
      const body = state.newCustomer;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SAVE_CUSTOMER", response);
          commit(SET_IS_UPLOADING, true);
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    [UPDATE_CUSTOMER]: async ({ commit, state }) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "customer/update-customer";
      const body = state.updateCustomer;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("UPDATE_CUSTOMER", response);
          commit(SET_IS_UPLOADING, true);
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    [DISABLE_CUSTOMER]: async ({ commit, state }, payload) => {
      console.log("DISABLE_CUSTOMER", commit);
      console.log("DISABLE_CUSTOMER", state);
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "customer/disable-customer";
      const body = { customer: payload.customer };

      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("DISABLE_CUSTOMER", response);
          // commit(SET_IS_UPLOADING, true);
        })
        .catch((err) => {
          // commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    [SAVE_BULK_CUSTOMERS_JSON]: async ({ state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "customers-json/save";
      const body = state.bulkCustomers;

      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SAVE_BULK_CUSTOMERS_JSON", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_BULK_CUSTOMER]: async ({ commit }, payload) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "execution";
      await axios
        .post(apiUrl, payload)
        .then((response) => {
          console.log("SAVE_BULK_CUSTOMER", response);
          if (response && response.data) {
            commit(
              SET_BULK_CUSTOMERS_EXEC_TASK_NAME,
              response.data.executionArn
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_BULK_CUSTOMER_STATUS]: async ({ commit, state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "execution/status";
      const body = {
        executionArn: state.bulkCustomersExecTaskName,
      };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SAVE_BULK_CUSTOMER_STATUS", response);
          if (response) {
            if ("output" in response.data) {
              commit(SET_IS_UPLOADING, true);
              commit(SET_BULK_CUSTOMERS_SAVE_STATUS, true);
            } else if (response.data.status == "FAILED") {
              // エラーになった場合
              commit(SET_IS_UPLOADING, true);
              commit(SET_BULK_CUSTOMERS_SAVE_STATUS, true);
            } else {
              commit(SET_IS_UPLOADING, true);
              commit(SET_BULK_CUSTOMERS_SAVE_STATUS, false);
            }
          }
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    /**
     * 得意先の従業員
     */
    [FETCH_CUSTOMER_STAFF_BY_ID]: async ({ commit }, payload) => {
      console.log("payload", payload);
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "customer/get-staffs-by-customer-id";
      await axios
        .get(apiUrl, {
          params: {
            customerId: payload.targetId,
          },
        })
        .then((response) => {
          console.log("FETCH_CUSTOMER_STAFF_BY_ID", response);
          let data = response.data.result;
          data.sort(function (a, b) {
            return moment(
              b.customer_staff_updated_at,
              "YYYY/MM/DD hh:mm:ss"
            ).diff(moment(a.customer_staff_updated_at, "YYYY/MM/DD hh:mm:ss"));
          });
          commit(SET_CUSTOMER_ALL_STAFF, data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_CUSTOMER_STAFF]: async ({ state, commit }, payload) => {
      console.log(state);
      console.log("payload", payload);
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "customer/save-customer-staff";
      const body = { staff: payload.newStaff };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SAVE_CUSTOMER_STAFF", response);
          commit(SET_NEW_STAFF_PDF, response.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [UPDATE_CUSTOMER_STAFF]: async ({ state }, payload) => {
      console.log(state);
      console.log("payload", payload);
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "customer/update-customer-staff";
      const body = { staff: payload.newStaff };

      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("UPDATE_CUSTOMER_STAFF", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [RESET_STAFF_PASS]: async ({ state }, payload) => {
      console.log(state);
      console.log("payload", payload);
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "customer/reset-customer-staff-pass";
      const body = { staff_id: payload.staff_id };

      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("RESET_STAFF_PASS", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [CREATE_STAFF_PASS_PDF]: async ({ state, commit }, payload) => {
      console.log(state);
      console.log("payload", payload);
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "pass-pdf/save";
      const all = [...state.customerStaffAll];
      const target = all.find(
        (item) => item.customer_staff_id == payload.staff_id
      );
      console.log("target", target);
      let body;
      if (target != undefined) {
        body = {
          customer_staff_login_id: target.customer_staff_login_id,
          customer_staff_password_display:
            target.customer_staff_password_display,
          company_name: target.customer.company_name,
        };
      } else {
        body = {
          customer_staff_login_id: payload.staff.customer_staff_login_id,
          customer_staff_password_display:
            payload.staff.customer_staff_password_display,
          company_name: payload.staff.customer.company_name,
        };
      }

      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("CREATE_STAFF_PASS_PDF", response);
          commit(SET_PASS_STAFF_PDF, response.data);
          // newStaffPdfを初期化する
          commit(SET_NEW_STAFF_PDF, null);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [CREATE_STAFF_QR_PDF]: async ({ state, commit }, payload) => {
      console.log(state);
      console.log("payload", payload);
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "qr-pdf/save";
      const data = new FormData();
      data.append("file", payload.qrData);
      data.append("storeName", payload.storeName);

      // QR保存～
      await axios({
        method: "POST",
        url: apiUrl,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          console.log("HOHOHOHOHOHO", response);
          commit(SET_QR_STAFF_PDF, response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [DELETE_CUSTOMER_STAFF]: async ({ state }) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "customer/update-customer";
      const body = state.updateCustomer;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("DELETE_CUSTOMER_STAFF", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [ENABLE_CUSTOMER_STAFF]: async ({ commit }, payload) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "customer/staffs/enable-account";
      const body = { staff: payload.staff };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("ENABLE_CUSTOMER_STAFF", response);
          commit(SET_SELECTED_CUSTOMER_STAFF, []);
        })
        .catch((err) => {
          console.error(err);
          commit(SET_SELECTED_CUSTOMER_STAFF, []);
        });
    },
    [DISABLE_CUSTOMER_STAFF]: async ({ commit }, payload) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "customer/staffs/disable-account";
      const body = { staff: payload.staff };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("DISABLE_CUSTOMER_STAFF", response);
          commit(SET_SELECTED_CUSTOMER_STAFF, []);
        })
        .catch((err) => {
          console.error(err);
          commit(SET_SELECTED_CUSTOMER_STAFF, []);
        });
    },
    [CANCEL_ASSOCIATE_CUSTOMER_STAFF]: async ({ state, commit }) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "customer/cancel-associate-id";
      const body = { staffs: state.selectedCustomerStaff };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("CANCEL_ASSOCIATE_EMPLOYEE", response);
          commit(SET_SELECTED_CUSTOMER_STAFF, []);
        })
        .catch((err) => {
          console.error(err);
          commit(SET_SELECTED_CUSTOMER_STAFF, []);
        });
    },
    /**
     * 担当者
     */
    [FETCH_EMPLOYEES]: async ({ commit }) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "employee/get-employee-all";
      await axios
        .get(apiUrl, {
          params: {},
        })
        .then((response) => {
          console.log("FETCH_EMPLOYEES", response);
          const data = response.data.result;
          commit(SET_EMPLOYEES, data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_EMPLOYEE]: async ({ commit, state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "employee/save-employee";
      const body = state.newEmployee;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          commit(SET_IS_UPLOADING, true);
          console.log("SAVE_EMPLOYEE", response);
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    [UPDATE_EMPLOYEE]: async ({ commit, state }) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "employee/update-employee";
      const body = state.updateEmployee;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          commit(SET_IS_UPLOADING, true);
          console.log("UPDATE_EMPLOYEE", response);
        })
        .catch((err) => {
          commit(SET_IS_UPLOADING, false);
          console.error(err);
        });
    },
    [ENABLE_EMPLOYEE]: async ({ commit }, payload) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "employee/enable-account";
      const body = { employee: payload.employee };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("ENABLE_EMPLOYEE", response);
          commit(SET_SELECTED_EMPLOYEES, []);
        })
        .catch((err) => {
          console.error(err);
          commit(SET_SELECTED_EMPLOYEES, []);
        });
    },
    [DISABLE_EMPLOYEE]: async ({ commit }, payload) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "employee/disable-account";
      const body = { employee: payload.employee };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("DISABLE_EMPLOYEE", response);
          commit(SET_SELECTED_EMPLOYEES, []);
        })
        .catch((err) => {
          console.error(err);
          commit(SET_SELECTED_EMPLOYEES, []);
        });
    },
    [CANCEL_ASSOCIATE_EMPLOYEE]: async ({ state, commit }) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "employee/cancel-associate-id";
      const body = { employees: state.selectedEmployees };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("CANCEL_ASSOCIATE_EMPLOYEE", response);
          commit(SET_SELECTED_EMPLOYEES, []);
        })
        .catch((err) => {
          console.error(err);
          commit(SET_SELECTED_EMPLOYEES, []);
        });
    },
    /**
     * 加工
     */
    [FETCH_PROCESSES]: async ({ commit }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "process/get-process";
      await axios
        .get(apiUrl, {
          params: {},
        })
        .then((response) => {
          console.log("FETCH_PROCESSES", response);
          const all = response.data.result;
          commit(SET_PROCESSES, all);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_PROCESSES]: async ({ commit, state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "process/save-process";
      const body = { process: state.process };

      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SAVE_PROCESSES", response);
          commit(SET_IS_UPLOADING, true);
        })
        .catch((err) => {
          console.error(err);
          commit(SET_IS_UPLOADING, false);
        });
    },
    [UPDATE_PROCESSES]: async ({ commit, state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "process/update-process";
      const body = { process: state.process };
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("UPDATE_PROCESSES", response);
          commit(SET_IS_UPLOADING, true);
        })
        .catch((err) => {
          console.error(err);
          commit(SET_IS_UPLOADING, false);
        });
    },
    /**
     * カテゴリー
     */
    [FETCH_LARGE_MIDDLE_CATEGORYS]: async ({ commit }) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "category/get-large-middle-categorys";
      await axios
        .get(apiUrl, {
          params: {},
        })
        .then((response) => {
          console.log("FETCH_LARGE_MIDDLE_CATEGORYS", response);
          if (response && response.data && response.data.result) {
            commit(SET_LARGE_MIDDLE_CATEGORY_LIST, response.data.result);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_CATEGORY]: async ({ state }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "category/save-category";
      const body = state.newCategory;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SAVE_CATEGORY", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [UPDATE_CATEGORY]: async ({ state }) => {
      const apiUrl =
        process.env.VUE_APP_API_DOMAIN + "category/update-category";
      const body = state.updateCategory;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("UPDATE_CATEGORY", response);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [GET_HOLIDAY_JSON]: async ({ commit }) => {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "holiday-json/get";
      await axios
        .get(apiUrl, {
          params: {},
        })
        .then((response) => {
          console.log("GET_HOLIDAY_JSON", response);
          if (response && response.data) {
            commit(SET_HOLIDAY_JSON, response.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [SAVE_JSON]: async ({ state }, payload) => {
      const target = payload.holidayJson;
      const apiUrl = process.env.VUE_APP_API_DOMAIN + "holiday-json/save";
      const body = target;
      await axios
        .post(apiUrl, body)
        .then((response) => {
          console.log("SAVE_JSON", response);
          console.log("state", state);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  modules: {},
});
